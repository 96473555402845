import axios from "axios";

function Settings() {


    return (
        <div>
            settings
        </div>
    )
}

export default Settings